import './components/sliders.js';
import './components/select.js';
import './components/burger.js';
import './components/spollers.js';
import './components/modals.js';
import './components/smoothScroll.js';
import './components/replaceEl.js';
import './components/phoneMask.js';







